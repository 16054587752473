// @import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;800&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");

$body-bg: #f3f4ff;
$body-color: #050516;

$theme-colors: (
  "site-primary": #201cb1,
  "site-pink": #ff2c6d,
  "site-secondary": #f3f4ff,
  "site-secondary1": #d1d5e0,
  "site-muted": #8fa2bb,
  "text-mute": #6e6e7d,
  "site-border-color": #e3e4e6,
  "site-nav-link": #060a0d,
  "site-primary1": #e8f0fe,
);
@import "node_modules/bootstrap/scss/bootstrap";
@import "./Calender.scss";

.font-family-p {
  font-family: "Questrial", sans-serif;
}
body {
  background-color: $body-bg;
  color: $body-color;
  height: 100%;
  font-family: "homepagebaukasten-bold", sans-serif;
}

.referral-code-page {
  padding-bottom: 5rem;
  .banner {
    height: 350px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: #fff;
    border-radius: 1rem;
    box-shadow: 0px 12px 24px -4px rgba(145, 158, 171, 0.12),
      0px 0px 2px 0px rgba(145, 158, 171, 0.2);
    h6 {
      font-size: 20px;
      color: theme-color("site-nav-link");
      line-height: 140%;
      padding-bottom: 1rem;
      span {
        font-size: 22px;
        // color: theme-color("site-primary");
        font-weight: 600;
        text-transform: capitalize;
      }
    }
    p {
      font-size: 14px;
      padding-left: 8px;
      margin: 0.75rem 0.5rem;
    }
    .code-input {
      border: 1px solid #dadbdd;
      max-width: 300px;
      border-radius: 8px;
      overflow: hidden;
      height: 48px;
      display: grid;
      align-items: center;
      grid-template-columns: auto max-content;
      h5 {
        color: #222325;
        font-size: 18px;
        padding: 0px 16px;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .bth-copy {
        cursor: pointer;
        height: 48px;
        width: 48px;
        background: theme-color("site-primary");
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .how-it-work {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    p {
      color: theme-color("site-primary");
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
    }
    .title {
      display: flex;
      align-items: center;
      h3 {
        font-size: 32px;
        line-height: 120%;
        flex: 1;
      }
    }
    .how-it-work-items {
      display: flex;
      padding-top: 1.5rem;
      gap: 3rem;
      .how-it-work-item {
        flex: 1;
        h4 {
          font-size: 20px;
          line-height: 140%;
          margin: 16px 0px 8px 0px;
        }
        h6 {
          font-size: 15px;
          color: theme-color("text-mute");
        }
      }
    }
  }
}

// dashboard
.dashboard-page {
  width: 100vw;
  height: 100vh;
  section {
    height: calc(100vh - 80px);
    width: 100%;
    .side-drawer {
      transform: translateX(0px);
      opacity: 1;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;
      min-width: 275px;
      height: 100%;
      .logout-btn {
        bottom: 0;
        right: 0;
        left: 0;
      }
      .nav-item-list {
        padding: 30px;
        h3 {
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 2rem;
          text-transform: capitalize;
        }
        .nav-item {
          cursor: pointer;
          display: flex;
          width: 100%;
          padding: 14px 16px;
          gap: 10px;
          align-items: center;
          span,
          svg {
            font-size: 16px;
            color: theme-color("text-mute");
            fill: theme-color("text-mute");
          }
        }
        .active-item {
          background: theme-color("site-primary");
          border-radius: 12px;
          span,
          svg {
            font-size: 16px;
            font-weight: 500;
            color: #fff;
            fill: #fff;
          }
        }
      }
    }
    .hide-side-drawer {
      transform: translateX(-100%);
      opacity: 0;
    }
    .page-section {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .T-contain {
      table {
        width: 100%;
        border-radius: 12px;
        th,
        td {
          padding: 16px;
          font-size: 16px;
          border-bottom: 1px solid theme-color("site-border-color");
          .profile-detail {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            img {
              width: 48px;
              height: 48px;
              border-radius: 100px;
            }
            h5 {
              font-size: 16px;
              font-weight: 500;
              margin: 0;
              color: theme-color("site-nav-link");
            }
          }
          .join-date {
            font-size: 16px;
            font-weight: 500;
            color: theme-color("text-mute");
            margin: 0;
          }
        }
        .select-user {
          text-align-last: end;
        }
        .action {
          text-align-last: center;
        }
      }
    }
    .T-header {
      padding-bottom: 2rem;
      h2 {
        flex: 1;
        font-size: 24px;
        color: theme-color("site-nav-link");
        margin: 0;
        strong {
          color: theme-color("site-primary");
        }
      }
    }
    .message-input {
      position: absolute;
      bottom: 15px;
      left: 15px;
      gap: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100% - 30px);
      input {
        height: 52px;
        border-radius: 12px;
        padding-right: 86px;
      }
      button {
        position: absolute;
        right: 12px;
        padding: 4px 14px;
      }
    }
    .profile-setting-page {
      display: flex;
      gap: 24px;
      button {
        height: 44px;
        border-radius: 10px;
      }
      .forms {
        display: flex;
        flex-direction: column;
        gap: 24px;
        .form-control {
          border-radius: 10px;
        }
        .form-group {
          margin-bottom: 16px;
        }
        p {
          font-size: 14px;
          margin-bottom: 6px;
        }
      }
      .paper {
        border-radius: 16px;
        .payment-form {
          input {
            shadow: "none";
          }
        }
      }
      .profile-image {
        display: flex;
        flex-direction: column;
        h6 {
          font-size: 24px;
          margin-bottom: 4px;
        }
        p {
          color: theme-color("text-mute");
          font-size: 16px;
        }
        img {
          width: 80px;
          margin-right: 12px;
        }
      }
    }
    .profile-page-heading {
      h3 {
        font-size: 24px;
        margin-bottom: 4px;
      }
      p {
        font-size: 16px;
        color: theme-color("site-muted");
        margin-bottom: 1.5rem;
      }
    }
    @media screen and (max-width: 900px) {
      .side-drawer {
        position: absolute !important;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 200;
      }
    }
  }
  .header {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .menu-icon {
      fill: theme-color("site-nav-link");
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
    .profile-avatar {
      height: 48px;
      width: 48px;
      background: theme-color("site-primary1");
      border-radius: 100px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    @media screen and (min-width: 900px) {
      .menu-icon {
        display: none;
      }
    }
  }
}

::-webkit-scrollbar {
  width: 0.313rem;
  height: 0.313rem;
}
::-webkit-scrollbar-track {
  background: theme-color("site-secondary1");
}
::-webkit-scrollbar-thumb {
  background-color: theme-color("site-muted"); /* color of the scroll thumb */
  border-radius: 1.25rem; /* roundness of the scroll thumb */
}
.lgn-heading {
  font-weight: 700;
}
.form-box {
  font-size: 12px;
  .form-icon {
    top: 50%;
    left: 20px;
    transform: translateY(-50%) scaleX(-1);
    // font-size: 13px;
    color: theme-color("site-secondary") !important;
  }
  input {
    font-size: 12px;
  }
}
.custom-checkbox {
  .custom-control-input ~ .custom-control-label {
    cursor: pointer;
    font-size: 12px;
  }
  .custom-control-input {
    &:checked {
      ~ .custom-control-label {
        &::before {
          border: theme-color("site-primary");
          background-color: theme-color("site-primary");
        }
      }
    }

    &:disabled {
      ~ .custom-control-label {
        &::before {
          border-color: #121212;
        }
      }

      &:checked {
        &::before {
          border-color: transparent;
        }
      }
    }
  }
}
.auth-bg {
  background-image: url("../img/dashboard-Slicing/BG.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  h1 {
    font-size: 42px;
    font-weight: 700;
  }
}
.register-btn,
.login-btn {
  padding: 0.8rem 0px;
  font-weight: 700;
}

.top-nav {
  height: 80px;
  background-color: $body-bg;
  display: flex;
  align-items: center;
  .logo {
    .link-logo {
      img {
        height: 75px;
      }
    }
    margin-right: 5%;
  }
  .form-control {
    background-color: theme-color("site-secondary") !important;
    border: none;
    width: 400px;
    height: 40px;
    border-radius: 60px;
    font-size: 13px;
    outline: none !important;
    @media screen and (max-width: 990px) {
      width: auto !important;
    }
  }

  .search-box {
    font-size: 12px;
    .search-icon {
      top: 25%;
      left: 20px;
      // font-size: 13px;
      color: theme-color("site-secondary") !important;
      @media screen and (max-width: 990px) {
        left: 10px;
      }
    }

    input {
      font-size: 12px;
    }
  }
  .profile {
    display: flex;
    align-items: center;
    color: $body-color;
    &:hover {
      text-decoration: none;
    }
    .profile-avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: theme-color("site-secondary");
      font-size: 12px;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      //   border: 3px solid var(--col-1);
      box-sizing: content-box !important;
      margin-left: 15px;
      overflow: hidden;
      :first {
        margin-left: 0px !important;
      }
      img {
        width: 50%;
      }
    }
  }
}
@media screen and (max-width: 990px) {
  .sidebar {
    padding-left: 0px !important;
    .side-top-nav {
      // height: 80px;
      // background-color: $body-bg;
      // display: flex;
      align-items: center;
      .side-profile {
        justify-content: space-between;
      }

      .form-control {
        background-color: theme-color("site-secondary") !important;
        border: none;
        width: 400px;
        height: 40px;
        border-radius: 60px;
        font-size: 13px;
        outline: none !important;
        @media screen and (max-width: 990px) {
          width: auto !important;
        }
      }

      .search-box {
        font-size: 12px;
        justify-content: space-between;
        //   margin-top: 0px !important;
        margin: 20px 10px;
        .search-icon {
          top: 25%;
          left: 20px;
          // font-size: 13px;
          color: theme-color("site-secondary") !important;
        }
        input {
          font-size: 12px;
        }
      }
      .profile {
        color: $body-color;

        &:hover {
          text-decoration: none;
        }
        .profile-avatar {
          display: flex;
          margin: auto;
          align-items: center;
          justify-content: center;
          background-color: theme-color("site-secondary");
          font-size: 12px;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          //   border: 3px solid var(--col-1);
          box-sizing: content-box !important;
          // margin-left: 15px;
          overflow: hidden;
          :first {
            margin-left: 0px !important;
          }
          img {
            width: 50%;
          }
        }
      }
    }
    .logout-btn {
      height: 41vh !important;
      .btn {
        background-color: #f86161;
      }
    }
  }
}
.sidebar {
  padding: 15px;
  padding-right: 0px !important;
  z-index: 100;
  //   overflow: hidden;
  width: 270px;
  transition: 0.5s;
  transition-property: width;
  //   height: calc(100vh - 80px);
  //   position: fixed;
  // top: 0;
  left: 0;

  .side-nav-items {
    // margin: 0 !important;
    margin-left: 15px;
    .side-nav-item {
      width: 100% !important;
      display: flex;
      padding: 10px;
      padding-left: 15px !important;
      .sidebar-icon {
        background-image: url("../img/dashboard-Slicing/Shape 5.svg");
        width: 18px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
      .sidebar-icon1 {
        background-image: url("../img/dashboard-Slicing/Profile.svg");
        width: 22px;
        background-position: top;
        background-repeat: no-repeat;
        background-size: contain;
      }
      .sidebar-icon2 {
        background-image: url("../img/dashboard-Slicing/Send.svg");
        width: 18px;
        background-position: top;
        background-repeat: no-repeat;
        background-size: contain;
      }
      .sidebar-icon3 {
        background-image: url("../img/dashboard-Slicing/Paper1.svg");
        width: 18px;
        background-position: top;
        background-repeat: no-repeat;
        background-size: contain;
      }
      .sidebar-icon4 {
        background-image: url("../img/dashboard-Slicing/Globe_icon_4.svg");
        width: 22px;
        background-position: top;
        opacity: 0.32;
        background-repeat: no-repeat;
        background-size: contain;
      }
      &:hover {
        text-decoration: none;
      }
      &.active {
        color: white !important;
        background-color: theme-color("site-primary");
        border-bottom-left-radius: 15px;
        border-top-left-radius: 15px;
        .sidebar-icon {
          background-image: url("../img/dashboard-Slicing/Dashboard.svg");
          width: 18px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }
        .sidebar-icon1 {
          background-image: url("../img/dashboard-Slicing/profile-svgrepo-com.svg");
          width: 22px;
          background-position: top;
          background-repeat: no-repeat;
          background-size: contain;
        }
        .sidebar-icon2 {
          background-image: url("../img/dashboard-Slicing/Send1.svg");
          width: 18px;
          background-position: top;
          background-repeat: no-repeat;
          background-size: contain;
        }
        .sidebar-icon3 {
          background-image: url("../img/dashboard-Slicing/Paper.svg");
          width: 18px;
          background-position: top;
          background-repeat: no-repeat;
          background-size: contain;
        }
        .sidebar-icon4 {
          background-image: url("../img/dashboard-Slicing/Globe_icon_4.svg");
          width: 22px;
          // opacity: 0.2;
          background-position: top;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
  }
  .logout-btn {
    height: 70vh;
    .btn {
      padding: 10px 35px;
      margin-bottom: 20px;
      background-color: #f86161;
      border: none;
    }
    // .logout-icon {
    //   transform: translateY(0%) scaleX(-1);
    // }
  }
}
.main-content {
  transition: 0.5s;
  transition-property: width, margin-left;
  // width: calc(100% - 215px);
  width: 100%;
  // margin-left: 215px;
  // overflow-y: scroll;
  padding: 25px;
  .T-heading {
    display: flex;
    color: theme-color("site-muted");
    justify-content: space-between;
    margin: 20px 40px 0px 10px;
    p {
      font-size: 17px;
      font-weight: 500;
    }
    @media screen and (max-width: 990px) {
      margin: 20px 10px 0px 10px !important;
    }
  }
  .T-contain {
    max-height: 550px;
    overflow-y: auto;
    @media screen and (max-width: 990px) {
      max-height: 445px;
    }
    .T-data {
      padding: 20px 40px;
      margin-bottom: 10px;
      border: none;
      .profile-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: theme-color("site-secondary");
        border-radius: 50%;
        width: 40px;
        height: 40px;
        box-sizing: content-box !important;
        margin-right: 30px;
        img {
          width: auto;
        }
      }
      h5 {
        margin: auto;
        margin-left: 0;
      }
      Button {
        border-radius: 50px;
        padding: 5px 12px !important;
        font-size: small;
        border: none;
        margin-top: auto;
        background-color: #f86161;
        margin-bottom: auto;
        //   margin-right: 0;
        font-weight: 700;
      }
      @media screen and (max-width: 990px) {
        padding: 10px !important;
        .profile-avatar {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: theme-color("site-secondary");
          border-radius: 50%;
          width: 30px;
          height: 30px;
          box-sizing: content-box !important;
          margin-right: 5px;
          img {
            width: auto;
          }
        }
        h5 {
          font-size: 12px;
          margin: auto;
          margin-left: 0;
        }
        Button {
          border-radius: 50px;
          padding: 3px 10px !important;
          font-size: x-small;
          margin-top: auto;
          margin-bottom: auto;
          //   margin-right: 0;
          font-weight: 700;
        }
      }
    }
  }
}
.modal-dialog {
  max-width: 40% !important;
}
@media screen and (max-width: 990px) {
  .modal-dialog {
    max-width: 70% !important;
    margin: auto !important;
  }
  .row-cols-md-2 {
    justify-content: start !important;
  }
}
@media screen and (max-width: 350px) {
  .modal-dialog {
    max-width: 100% !important;
    margin: auto !important;
  }
}

@media screen and (max-width: 1300px) {
  .header p br {
    display: none;
  }
}

.close-AM {
  cursor: pointer;
}

.flex-0 {
  flex: 0 !important;
}

.form-control-M {
  background-color: theme-color("site-secondary") !important;
  border: none;
  // width: 400px;
  height: 60px;
  border-radius: 0.25rem;
  font-size: 13px !important;
  outline: none !important;
  @media screen and (max-width: 990px) {
    // width: auto !important;
  }
}

.Ad-user-text {
  font-size: 12px;
  .Ad-user-icon {
    top: 30%;
    left: 20px;
    // font-size: 13px;
    color: theme-color("site-secondary") !important;
  }
  input {
    font-size: 12px;
  }
}
.remove-user-btns {
  display: flex;
  justify-content: center;
  margin: 20px;
  Button {
    margin: 0px 10px;
    border-radius: 50px;
    padding: 10px 20px;
    font-size: 20px;
    font-weight: 500;
    color: white;
  }
}

.C-logo {
  font-weight: 700 !important;
  color: #4741db;
  font-size: small;
  &:hover {
    text-decoration: none;
    color: #4741db;
  }
}
.card-form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

// Send messsage page

.typeM {
  padding: 20px 35px;
  Button {
    display: flex;
    border-radius: 50px;
    margin-left: auto;
    padding: 5px 20px;
    font-size: small;
    border: none;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 700;
  }
}
.S-users {
  .S-all {
    display: flex;
    margin: 20px;
    p {
      font-size: 17px;
      margin-bottom: 0;
      font-weight: 500;
      color: theme-color("site-muted");
    }
  }
  .users {
    display: flex;
    flex-direction: row;
    padding: 20px 40px;
    padding-left: 20px;
    margin-bottom: 10px;
    .profile-avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: theme-color("site-secondary");
      border-radius: 50%;
      width: 40px;
      height: 40px;
      box-sizing: content-box !important;
      margin-right: 30px;
      img {
        width: auto;
      }
    }
    h5 {
      margin: auto;
      margin-left: 0;
    }
  }
  .option-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto 0px;
    border-radius: 7px;
    height: 22px;
    width: 22px;
    transition: all 0.15s ease-out 0s;
    border: 2px solid theme-color("site-muted");
    color: #fff;
    cursor: pointer;
    display: inline-block;
    margin-right: 1.5rem;
    outline: none;
    position: relative;
    z-index: 1000;
  }
  .option-input:checked {
    background: theme-color("site-muted");
  }
  .option-input:checked::before {
    height: 22px;
    width: 20px;
    position: absolute;
    content: "✔";
    display: inline-block;
    font-size: 16px;
    text-align: center;
    line-height: 20px;
  }
  .option-input:checked::after {
    -webkit-animation: click-wave 0.65s;
    -moz-animation: click-wave 0.65s;
    animation: click-wave 0.65s;
    background: theme-color("site-muted");
    content: "";
    display: block;
    position: relative;
    z-index: 100;
  }
}
//invoice page
.invoice-sec {
  .invoice {
    display: flex;
    flex-direction: row;
    padding: 20px 30px;
    margin-bottom: 10px;
    .invoice-avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: theme-color("site-secondary");
      border-radius: 50%;
      width: 40px;
      height: 40px;
      box-sizing: content-box !important;
      margin-right: 30px;
      img {
        width: 15px;
      }
    }
    h5 {
      margin: auto;
      margin-left: 0;
    }
  }
  Button {
    padding: 5px 20px;
    font-size: small;
    border-radius: 50px;
    border: none;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 700;
  }
}

//landing page

.header {
  background-color: white;
  &-btn-section {
    margin-top: 19% !important;
    @media (max-width: 574.99px) {
      margin-top: auto !important;
    }
  }

  @media (max-width: 770px) {
    .f-coll {
      margin: 0px !important;
      padding: 0px !important;
    }
    p {
      br {
        display: none;
      }
    }
  }

  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    @media (max-width: 1150px) {
      font-size: 2rem;
    }
    @media (max-width: 990px) {
      font-size: 1.9rem;
    }
    @media (max-width: 768px) {
      font-size: 1.55rem;
    }
    @media (max-width: 500px) {
      font-size: 1.4rem;
    }
    @media (max-width: 400px) {
      font-size: 1.2rem;
    }
  }
  .f-row {
    margin-left: 4rem;
    min-height: 100vh;
    z-index: 999;
    // margin-top: -5rem;
    @media (max-width: 990px) {
      margin-top: 0;
      padding: 50px 0;
    }
    @media (max-width: 768px) {
      padding: 50px 0;
    }
    @media (max-width: 575px) {
      padding: 80px 0 0px;
      margin-left: 0;
    }
  }
  .f-coll {
    // margin-top: auto;
    // margin-left: 15%;
    margin-top: 26% !important;
    @media (max-width: 574.99px) {
      margin-top: 5% !important;
    }
  }
  .f-colr {
    padding: 0;
    padding-top: 35px;
    img {
      padding-left: 270px;
      width: 100%;
      @media (max-width: 1450px) {
        padding-left: 200px;
      }
      @media (max-width: 1300px) {
        padding-left: 130px;
      }
      @media (max-width: 1050px) {
        padding-left: 80px;
      }
      @media (max-width: 768px) {
        padding: 0;
      }
      @media (max-width: 575px) {
        padding: 0;
        width: 80%;
        // margin: auto !important;
        margin-left: 70px;
        text-align: center;
      }
    }
  }
  .header-btn {
    width: 21%;
    margin-right: 20px;
    @media (max-width: 1650px) {
      width: 21%;
    }
    @media (max-width: 1450px) {
      width: 24%;
    }
    @media (max-width: 1300px) {
      width: 26%;
    }
    @media (max-width: 1150px) {
      width: 24%;
    }
    @media (max-width: 1050px) {
      width: 26%;
    }
    img {
      width: 100%;
    }
  }
  .wave-section-separator {
    z-index: 0;

    .wave {
      fill: theme-color(site-primary);
    }
  }
}
.navbar-style {
  // font-family: "Nunito", sans-serif;
  padding-left: 7%;
  padding-right: 7%;
  position: fixed;
  width: 100%;
  height: 80px;
  background-color: #fff;
  z-index: 9999;
  @media (max-width: 991.98px) {
    border: 0;
    padding-left: 2%;
    padding-right: 2%;
    box-shadow: none;
    position: fixed;
    width: -webkit-fill-available;
    height: auto;
  }
  .navbar-header {
    z-index: 1;
    width: 7%;
    @media (max-width: 1200px) and (min-width: 991px) {
      width: 6%;
    }
    @media (max-width: 575px) {
      z-index: 200;
      width: 12%;
    }
    .logo-text {
      width: 150px;
      @media (max-width: 1320px) {
        width: 140px;
      }
      @media (max-width: 1200px) and (min-width: 991px) {
        width: 130px;
      }
    }
  }
  .navbar-logo {
    height: auto;
    width: 100px;
  }
  .navbar-collapse {
    @media (max-width: 990px) {
      margin-top: -100px;
      background-color: #ffffff26;
    }
  }
  .navbar-nav {
    @media (max-width: 990px) {
      margin-top: 90px;
    }
  }
  .nav-item {
    margin: auto;
    @media (max-width: 990px) {
      margin-right: auto;
      margin-left: 10px;
      padding-top: 100px;
      padding: 10px 0;
    }
    .navlink {
      padding: 10px 25px;
      color: $body-color;
      font-size: 1.1rem;
      font-weight: 700;
      cursor: pointer;
      &:hover {
        text-decoration: none;
        color: theme-color("site-primary");
      }
      @media (max-width: 1320px) {
        padding: 10px 15px;
      }
      @media (max-width: 1180px) {
        font-size: 1rem;
        padding: 10px 13px;
      }
      @media (max-width: 1045px) {
        padding: 10px 10px;
      }
    }
    .navlink-active {
      color: theme-color("site-primary");
      // font-weight: 600;
      text-decoration: none;
      // border-bottom: 2px solid theme-color('site-primary');
    }
  }
  .nav-btn {
    border-radius: 50px;
    font-size: 1.1rem;
    font-weight: 600;
    padding: 5px 20px;
    margin: 0px 10px;
  }
}
.navbar-toggler {
  z-index: 200;
}
.navbar-toggler-icon {
  background-image: url("../img/Landing\ page-slicing/download.svg");
  background-position: top;
  background-repeat: no-repeat;
  z-index: 200;
  cursor: pointer;
}
.active {
  .navbar-toggler-icon {
    background-image: url("../img/Landing\ page-slicing/times.png") !important;
    background-position: top;
    z-index: 200;
    background-repeat: no-repeat;
  }
}

//about
.about {
  margin: 10px 12rem 50px;
  @media (max-width: 1800px) {
    margin: 10px 7rem 50px;
  }
  @media (max-width: 1418px) {
    margin: 10px 5rem 50px;
  }
  @media (max-width: 1206px) {
    margin: 10px 4rem 50px;
  }
  // @media (max-width: 990px) {
  //   margin: 10px 4rem 50px;
  // }
  @media (max-width: 768px) {
    margin: 10px 2rem 50px;
  }
  // .row {
  //   align-items: center;
  //   @media (max-width: 1280px) {
  //     align-items: flex-start;
  //   }
  // }
  .eteImg {
    width: 350px;
    @media (max-width: 1280px) {
      width: 100%;
    }
    @media (max-width: 767.98px) {
      width: 250px;
      display: flex;
      justify-content: center;
      margin: auto;
    }
  }
  .contactImg {
    width: 350px;
    @media (max-width: 1280px) {
      width: 100%;
    }
    @media (max-width: 767.98px) {
      width: 250px;
      display: flex;
      justify-content: center;
      margin: auto;
    }
  }
  h1 {
    color: $body-color;
    margin: 40px 0;
    font-weight: 700;
    font-size: 2.5rem;
    @media (max-width: 1350px) {
      font-size: 3.5rem;
    }
    @media (max-width: 1294px) {
      font-size: 3.3rem;
    }
    @media (max-width: 1175px) {
      font-size: 3rem;
    }
    @media (max-width: 1100px) {
      font-size: 2.8rem;
    }
    @media (max-width: 1056px) {
      font-size: 1.8rem;
      margin: 20px 0px;
    }
    @media (max-width: 1010px) {
      font-size: 2.8rem;
    }
    @media (max-width: 950px) {
      font-size: 2rem;
      margin: 20px 0px;
    }
    @media (max-width: 768px) {
      font-size: 2.25rem;
      margin: 15px 0 !important;
      // text-align: center;
    }
    @media (max-width: 500px) {
      font-size: 2rem;
    }
    @media (max-width: 400px) {
      font-size: 1.8rem;
      margin: 40px 0 20px;
    }
  }
  h2 {
    color: theme-color("text-mute");
    font-size: 1.4rem !important;
    @media (max-width: 1450px) {
      font-size: 1.5rem;
    }
    @media (max-width: 1300px) {
      font-size: 1.53rem;
    }
    @media (max-width: 1200px) {
      font-size: 1.45rem;
    }
    @media (max-width: 1100px) {
      font-size: 1.3rem;
    }
    @media (max-width: 990px) {
      // text-align: justify;
      font-size: 1rem !important;
    }
    @media (max-width: 768px) {
      font-size: 0.8rem !important;
    }
    @media (max-width: 500px) {
      font-size: 1.1rem;
    }
    @media (max-width: 400px) {
      font-size: 0.8rem;
    }
    // margin-bottom: 40px;
  }
}
.about2 {
  margin: auto;
  h1 {
    text-align: center;
    font-weight: 700;
    padding-top: 50px;
    font-size: 2.5rem;
    @media (max-width: 1150px) {
      font-size: 2rem;
    }
    @media (max-width: 990px) {
      font-size: 1.9rem;
    }
    @media (max-width: 768px) {
      font-size: 1.55rem;
    }
    @media (max-width: 500px) {
      font-size: 1.4rem;
    }
    @media (max-width: 400px) {
      font-size: 1.2rem;
    }
  }
  .img-div {
    margin: 20px 70px;
    justify-content: center;
    @media (max-width: 1200px) {
      margin: 20px 50px;
    }
    @media (max-width: 990px) {
      margin: 20px 30px;
    }
    @media (max-width: 768px) {
      margin: 20px 100px;
    }
    @media (max-width: 500px) {
      margin: 20px 80px;
    }
    @media (max-width: 400px) {
      margin: 20px 60px;
    }
    div {
      // margin: -8px;
      img {
        width: 100%;
      }
    }
  }
}

//features

.features {
  .f-tabs-img {
    width: 10%;
    @media (max-width: 990px) {
      width: 15% !important;
    }
    @media (max-width: 500px) {
      width: 20% !important;
    }
    @media (max-width: 350px) {
      width: 25% !important;
    }
  }
  .imgW {
    width: 55%;
    @media (max-width: 1150px) {
      width: 70%;
    }
    @media (max-width: 950px) {
      width: 80%;
    }
    // @media (max-width: 1250px) {
    //   margin-left: 60px;
    // }
    @media (max-width: 800px) {
      width: 110%;
    }
    @media (max-width: 767px) {
      width: 70%;
    }
  }
  h1 {
    font-size: 2.5rem;
    @media (max-width: 1150px) {
      font-size: 2rem;
    }
    @media (max-width: 990px) {
      font-size: 1.9rem;
    }
    @media (max-width: 768px) {
      font-size: 1.55rem;
    }
    @media (max-width: 500px) {
      font-size: 1.4rem;
      padding: 30px;
    }
  }
  .col_right {
    div {
      margin-left: 130px;
      @media (max-width: 1450px) {
        margin-left: 100px;
      }
      @media (max-width: 1350px) {
        margin-left: 80px;
      }
      @media (max-width: 1250px) {
        margin-left: 60px;
      }
      @media (max-width: 990px) {
        margin-left: 150px;
        margin-top: 10px;
      }
      @media (max-width: 900px) {
        margin-left: 100px;
      }
    }
  }
  .col_left {
    padding-left: 100px;
    margin-top: 10px;
    text-align: left;
    @media (max-width: 1200px) {
      padding-left: 50px;
      margin-top: 10px;
    }

    div {
      margin: 50px 10px;
      @media (max-width: 1200px) {
        margin: 40px 10px;
      }
      @media (max-width: 990px) {
        margin: 30px 10px;
      }
      @media (max-width: 768px) {
        // margin: auto;
        // justify-content: center;
      }
      h2 {
        font-size: 2rem;
        @media (max-width: 1100px) {
          font-size: 1.7rem;
        }
        @media (max-width: 990px) {
          font-size: 1.4rem;
        }
        @media (max-width: 768px) {
          font-size: 1.3rem;
        }
        @media (max-width: 500px) {
          font-size: 1.2rem;
        }
      }
    }
  }
  .tab-heading {
    @media (max-width: 1150px) {
      font-size: 1.7rem;
    }
    @media (max-width: 990px) {
      font-size: 1.9rem;
    }
    @media (max-width: 768px) {
      font-size: 1.8rem;
    }
    @media (max-width: 500px) {
      font-size: 1.7rem;
    }
  }
  .imgF {
    width: 100%;
    @media (max-width: 768px) {
      width: 70%;
    }
  }
  .row2 {
    .col {
      div {
        // display: flex;
        display: none;
        justify-content: center;
      }
    }
  }
  .cards {
    padding-bottom: 50px;
    h1 {
      text-align: center;
      padding: 70px;
      font-weight: 700;
      font-size: 2.5rem;
      @media (max-width: 1150px) {
        font-size: 2rem;
      }
      @media (max-width: 990px) {
        font-size: 1.9rem;
      }
      @media (max-width: 768px) {
        font-size: 1.55rem;
      }
      @media (max-width: 500px) {
        font-size: 1.4rem;
        padding: 30px;
      }
      // @media (max-width: 400px) {
      //   font-size: 1.2rem;
      // }
    }
    .row3 {
      margin: 0 90px;
      @media (max-width: 1200px) {
        margin: 0 80px;
      }
      @media (max-width: 990px) {
        margin: 0 80px;
      }
      @media (max-width: 450px) {
        margin: 0 20px;
      }
      display: flex;
      justify-content: center;
      .card {
        background-color: theme-color("site-primary");
        // font-family: 'Nunito', sans-serif;
        color: #fff;
        min-height: 100%;
        border: none;
        margin: auto;
        // width: 80%;
        padding: 40px;
        border-radius: 20px;
        @media (min-width: 990px) and (max-width: 1150px) {
          padding: 40px 20px;
        }
        @media (max-width: 380px) {
          padding: 20px;
        }
        img {
          width: fit-content;
          margin: auto;
          @media (max-width: 1200px) {
            width: 50%;
          }
        }
        .card-body {
          padding-bottom: 0;
          @media (max-width: 1450px) {
            padding-left: 10px;
            padding-right: 10px;
          }
          @media (max-width: 1300px) {
            padding-left: 0;
            padding-right: 0;
          }
          h2 {
            font-size: 1.9rem;
            @media (max-width: 1200px) {
              font-size: 1.7rem;
            }
            @media (max-width: 990px) {
              font-size: 1.5rem;
            }
            // @media (max-width: 830px) {
            //   font-size: 1.3rem;
            // }
            // @media (max-width: 751px) {
            //   font-size: 1.1rem;
            // }
            // @media (max-width: 500px) {
            //   font-size: 0.8rem;
            //   // padding: 30px;
            // }
          }
          .card-text {
            font-size: 1.2rem;
            @media (max-width: 1400px) {
              font-size: 1rem;
            }
            @media (max-width: 1200px) {
              font-size: 0.93rem;
            }
            @media (max-width: 990px) {
              font-size: 0.9rem;
            }
            // @media (max-width: 830px) {
            //   font-size: 2.5rem;
            // }
            // @media (max-width: 768px) {
            //   font-size: 0.8rem;
            // }
            // @media (max-width: 500px) {
            //   font-size: 0.9rem;
            //   // padding: 30px;
            // }
          }
        }
      }
    }
  }
  .cards2 {
    padding-bottom: 50px;
    h1 {
      text-align: center;
      padding: 40px;
      font-weight: bolder;
      font-size: 2.5rem;
      @media (max-width: 1150px) {
        font-size: 2rem;
      }
      @media (max-width: 990px) {
        font-size: 1.9rem;
      }
      @media (max-width: 768px) {
        font-size: 1.55rem;
      }
      @media (max-width: 500px) {
        font-size: 1.4rem;
        padding: 30px;
      }
      // @media (max-width: 400px) {
      //   font-size: 1.2rem;
      // }
    }
    .row4 {
      margin: 0 90px;
      @media (max-width: 1200px) {
        margin: 0 80px;
      }
      @media (max-width: 990px) {
        margin: 0 40px;
      }
      @media (max-width: 450px) {
        margin: 0 0px;
      }
      .card {
        background-color: #fff;
        // font-family: 'Nunito', sans-serif;
        color: $body-color;
        min-height: 100%;
        border: none;
        margin: auto;
        width: auto;
        padding: 23% 0;
        // padding: 40px;
        border-radius: 20px;
        @media (max-width: 1200px) {
          padding: 30px 0;
        }
        @media (max-width: 380px) {
          padding: 20px 0;
        }
        img {
          width: 30%;
          margin: auto;
          @media (max-width: 1200px) {
            width: 30%;
          }
        }
        .card13 {
          padding: 0 !important;
          // .card-textt{
          //   font-size: 0.9rem !important;
          // }
        }
        .card-body {
          padding-bottom: 25px;
          padding-left: 4.2rem;
          padding-right: 4.2rem;
          flex-direction: column;
          display: flex;
          justify-content: end;
          @media (max-width: 1700px) {
            padding-left: 3rem;
            padding-right: 3rem;
          }
          @media (max-width: 1500px) {
            padding-left: 3.5rem;
            padding-right: 3.5rem;
          }
          @media (max-width: 1300px) {
            padding-left: 1rem;
            padding-right: 1rem;
          }
          @media (max-width: 1200px) {
            padding-left: 2rem;
            padding-right: 2rem;
          }
          @media (max-width: 1150px) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
          }
          @media (max-width: 1100px) {
            padding-left: 1rem;
            padding-right: 1rem;
          }
          @media (max-width: 600px) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
          }
          h2 {
            font-size: 1.9rem;
            @media (max-width: 1200px) {
              font-size: 1.7rem;
            }
            @media (max-width: 1100px) {
              font-size: 1.5rem;
            }
            @media (max-width: 990px) {
              font-size: 1.5rem;
            }
          }
          .card-text {
            font-size: 1.17rem;
            @media (max-width: 1500px) {
              font-size: 1rem;
            }
            // @media (max-width: 1500px) {
            //   font-size: 1rem;
            // }
            // @media (max-width: 1400px) {
            //   font-size: 0.88rem;
            // }
            // @media (max-width: 1300px) {
            //   font-size: 0.7rem;
            // }
            @media (max-width: 1200px) {
              font-size: 0.93rem;
            }
            @media (max-width: 1100px) {
              font-size: 0.81rem;
            }
            @media (max-width: 990px) {
              font-size: 0.9rem;
            }
            @media (max-width: 600px) {
              font-size: 0.7rem;
            }
          }
        }
      }
    }
  }
}

//indusry

.industry {
  .cards {
    padding-bottom: 50px;
    h1 {
      text-align: center;
      padding: 70px;
      font-weight: 700;
      font-size: 2.5rem;
      @media (max-width: 1150px) {
        font-size: 2rem;
      }
      @media (max-width: 990px) {
        font-size: 1.9rem;
      }
      @media (max-width: 768px) {
        font-size: 1.55rem;
      }
      @media (max-width: 500px) {
        font-size: 1.4rem;
        padding: 30px;
      }
      // @media (max-width: 400px) {
      //   font-size: 1.2rem;
      // }
    }
    .row3 {
      margin: 0 90px;
      @media (max-width: 1200px) {
        margin: 0 80px;
      }
      @media (max-width: 990px) {
        margin: 0 80px;
      }
      @media (max-width: 450px) {
        margin: 0 20px;
      }
      display: flex;
      justify-content: center;
      .card {
        background-color: theme-color("site-primary");
        // font-family: 'Nunito', sans-serif;
        color: #fff;
        min-height: 100%;
        border: none;
        margin: auto;
        // width: 80%;
        padding: 40px;
        border-radius: 20px;
        @media (min-width: 990px) and (max-width: 1150px) {
          padding: 40px 20px;
        }
        @media (max-width: 380px) {
          padding: 20px;
        }
        img {
          width: fit-content;
          margin: auto;
          @media (max-width: 1200px) {
            width: 50%;
          }
        }
        .card-body {
          padding-bottom: 0;
          @media (max-width: 1450px) {
            padding-left: 10px;
            padding-right: 10px;
          }
          @media (max-width: 1300px) {
            padding-left: 0;
            padding-right: 0;
          }
          h2 {
            font-size: 1.9rem;
            @media (max-width: 1200px) {
              font-size: 1.7rem;
            }
            @media (max-width: 990px) {
              font-size: 1.5rem;
            }
            // @media (max-width: 830px) {
            //   font-size: 1.3rem;
            // }
            // @media (max-width: 751px) {
            //   font-size: 1.1rem;
            // }
            // @media (max-width: 500px) {
            //   font-size: 0.8rem;
            //   // padding: 30px;
            // }
          }
          .card-text {
            font-size: 1.2rem;
            @media (max-width: 1400px) {
              font-size: 1rem;
            }
            @media (max-width: 1200px) {
              font-size: 0.93rem;
            }
            @media (max-width: 990px) {
              font-size: 0.9rem;
            }
            // @media (max-width: 830px) {
            //   font-size: 2.5rem;
            // }
            // @media (max-width: 768px) {
            //   font-size: 0.8rem;
            // }
            // @media (max-width: 500px) {
            //   font-size: 0.9rem;
            //   // padding: 30px;
            // }
          }
        }
      }
    }
  }
  .cards2 {
    padding-bottom: 50px;
    h1 {
      text-align: center;
      padding: 40px;
      font-weight: bolder;
      font-size: 2.5rem;
      @media (max-width: 1150px) {
        font-size: 2rem;
      }
      @media (max-width: 990px) {
        font-size: 1.9rem;
      }
      @media (max-width: 768px) {
        font-size: 1.55rem;
      }
      @media (max-width: 500px) {
        font-size: 1.4rem;
        padding: 30px;
      }
      // @media (max-width: 400px) {
      //   font-size: 1.2rem;
      // }
    }
    .row4 {
      margin: 0 90px;
      @media (max-width: 1200px) {
        margin: 0 80px;
      }
      @media (max-width: 990px) {
        margin: 0 40px;
      }
      @media (max-width: 450px) {
        margin: 0 0px;
      }
      .card {
        background-color: #fff;
        // font-family: 'Nunito', sans-serif;
        color: $body-color;
        min-height: 100%;
        border: none;
        margin: auto;
        width: auto;
        padding: 15% 0;
        // padding: 40px;
        border-radius: 20px;
        // @media (max-width: 1200px) {
        //   padding: 30px 5px;
        // }
        @media (max-width: 380px) {
          padding: 20px 0;
        }
        img {
          width: 30%;
          margin: auto;
          @media (max-width: 1200px) {
            width: 30%;
          }
        }
        .card13 {
          padding: 0 !important;
          // .card-textt{
          //   font-size: 0.9rem !important;
          // }
        }
        .card-body {
          padding-bottom: 25px;
          padding-left: 4.2rem;
          padding-right: 4.2rem;
          flex-direction: column;
          display: flex;
          justify-content: end;
          @media (max-width: 1700px) {
            padding-left: 3rem;
            padding-right: 3rem;
          }
          @media (max-width: 1500px) {
            padding-left: 3.5rem;
            padding-right: 3.5rem;
          }
          @media (max-width: 1300px) {
            padding-left: 1rem;
            padding-right: 1rem;
          }
          @media (max-width: 1200px) {
            padding-left: 2rem;
            padding-right: 2rem;
          }
          @media (max-width: 1150px) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
          }
          @media (max-width: 1100px) {
            padding-left: 1rem;
            padding-right: 1rem;
          }
          @media (max-width: 600px) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
          }
          h2 {
            font-size: 1.9rem;
            @media (max-width: 1200px) {
              font-size: 1.7rem;
            }
            @media (max-width: 1100px) {
              font-size: 1.5rem;
            }
            @media (max-width: 990px) {
              font-size: 1.5rem;
            }
          }
          .card-text {
            font-size: 1.17rem;
            @media (max-width: 1500px) {
              font-size: 1rem;
            }
            // @media (max-width: 1500px) {
            //   font-size: 1rem;
            // }
            // @media (max-width: 1400px) {
            //   font-size: 0.88rem;
            // }
            // @media (max-width: 1300px) {
            //   font-size: 0.7rem;
            // }
            @media (max-width: 1200px) {
              font-size: 0.93rem;
            }
            @media (max-width: 1100px) {
              font-size: 0.81rem;
            }
            @media (max-width: 990px) {
              font-size: 0.9rem;
            }
            @media (max-width: 600px) {
              font-size: 0.7rem;
            }
          }
        }
      }
    }
  }
}

//benefits
.benefits {
  .top {
    padding-bottom: 50px;
    margin: 10px 11rem 50px;
    @media (max-width: 1800px) {
      margin: 10px 6rem 50px;
    }
    @media (max-width: 1400px) {
      margin: 10px 4.5rem 50px;
    }
    @media (max-width: 1200px) {
      margin: 10px 3.5rem 50px;
    }
    // @media (max-width: 990px) {
    //   margin: 10px 4rem 50px;
    // }
    @media (max-width: 768px) {
      margin: 10px 1rem 50px;
    }

    h1 {
      color: $body-color;
      margin: 40px 0;
      font-weight: 700;
      font-size: 2.5rem;
      @media (max-width: 1350px) {
        font-size: 2.5rem;
      }
      @media (max-width: 1280px) {
        font-size: 2.5rem;
      }
      @media (max-width: 1150px) {
        font-size: 2.3rem;
      }
      @media (max-width: 1070px) {
        font-size: 2.1rem;
      }
      @media (max-width: 990px) {
        font-size: 1.8rem;
      }
      @media (max-width: 768px) {
        font-size: 1.55rem;
        // text-align: center;
      }
      @media (max-width: 500px) {
        font-size: 1.4rem;
      }
      @media (max-width: 400px) {
        font-size: 1.2rem;
        margin: 40px 0 20px;
      }
    }
    p {
      font-size: 14px;
      width: 87%;
      @media (max-width: 1350px) {
        font-size: 12px;
        width: 92%;
      }
    }
    .row {
      // margin: 0 90px;
      // @media (max-width: 1200px) {
      //   margin: 0 80px;
      // }
      // @media (max-width: 990px) {
      //   margin: 0 80px;
      // }
      // @media (max-width: 450px) {
      //   margin: 0 20px;
      // }
      .col {
        margin: 25px 0;
      }
    }
  }
  .cards {
    padding-bottom: 50px;
    h1 {
      text-align: center;
      padding: 70px;
      font-weight: 700;
      font-size: 2.5rem;
      @media (max-width: 1150px) {
        font-size: 2rem;
      }
      @media (max-width: 990px) {
        font-size: 1.9rem;
      }
      @media (max-width: 768px) {
        font-size: 1.55rem;
      }
      @media (max-width: 500px) {
        font-size: 1.4rem;
        padding: 30px;
      }
      // @media (max-width: 400px) {
      //   font-size: 1.2rem;
      // }
    }
    .row3 {
      margin: 0 90px;
      @media (max-width: 1200px) {
        margin: 0 80px;
      }
      @media (max-width: 990px) {
        margin: 0 80px;
      }
      @media (max-width: 450px) {
        margin: 0 20px;
      }
      display: flex;
      justify-content: center;
      .card {
        background-color: theme-color("site-primary");
        // font-family: 'Nunito', sans-serif;
        color: #fff;
        min-height: 100%;
        border: none;
        margin: auto;
        // width: 80%;
        padding: 40px;
        border-radius: 20px;
        @media (min-width: 990px) and (max-width: 1150px) {
          padding: 40px 20px;
        }
        @media (max-width: 380px) {
          padding: 20px;
        }
        img {
          // width: fit-content;
          margin: auto;
          // @media (max-width: 1200px) {
          width: 50%;
          @media (min-width: 500px) and (max-width: 767.98px) {
            width: 35%;
          }
          // }
          // text-align: center;
        }
        .card-body {
          padding-bottom: 0;
          @media (max-width: 1450px) {
            padding-left: 10px;
            padding-right: 10px;
          }
          @media (max-width: 1300px) {
            padding-left: 0;
            padding-right: 0;
          }
          h2 {
            font-size: 1.9rem;
            @media (max-width: 1200px) {
              font-size: 1.7rem;
            }
            @media (max-width: 990px) {
              font-size: 1.5rem;
            }
            // @media (max-width: 830px) {
            //   font-size: 1.3rem;
            // }
            // @media (max-width: 751px) {
            //   font-size: 1.1rem;
            // }
            // @media (max-width: 500px) {
            //   font-size: 0.8rem;
            //   // padding: 30px;
            // }
          }
          .card-text {
            font-size: 1.2rem;
            @media (max-width: 1400px) {
              font-size: 1rem;
            }
            @media (max-width: 1200px) {
              font-size: 0.93rem;
            }
            @media (max-width: 990px) {
              font-size: 0.9rem;
            }
            // @media (max-width: 830px) {
            //   font-size: 2.5rem;
            // }
            // @media (max-width: 768px) {
            //   font-size: 0.8rem;
            // }
            // @media (max-width: 500px) {
            //   font-size: 0.9rem;
            //   // padding: 30px;
            // }
          }
        }
      }
    }
  }
}
//pricing
.pricing {
  .title {
    text-align: center;
    padding: 40px;
    font-weight: bolder;
    font-size: 2.5rem;
    @media (max-width: 1150px) {
      font-size: 2rem;
    }
    @media (max-width: 990px) {
      font-size: 1.9rem;
    }
    @media (max-width: 768px) {
      font-size: 1.55rem;
    }
    @media (max-width: 500px) {
      font-size: 1.4rem;
      padding: 30px;
    }
    // @media (max-width: 400px) {
    //   font-size: 1.2rem;
    // }
  }
  .p-row {
    // margin: 0 90px;
    padding-bottom: 50px;
    // @media (max-width: 1300px) {
    //   margin: 0 70px;
    // }
    // @media (max-width: 1200px) {
    //   margin: 0 50px;
    // }
    @media (max-width: 1199.98px) {
      //   margin: 0 40px;
      // }
      // @media (max-width: 990px) {
      //   margin: 0 40px;
      // }
      // @media (max-width: 450px) {
      margin: 0 20px;
    }

    @media (max-width: 400px) {
      margin: 0;
    }
    .col {
      padding-left: 10px;
      padding-right: 10px;
      @media (max-width: 1150px) {
        padding-left: 5px;
        padding-right: 5px;
      }
      @media (max-width: 990px) {
        padding: 10px;
      }
      @media (max-width: 800px) {
        padding: 10px 5px;
      }
    }

    .card {
      border: 0;
      border-radius: 0px;
      // -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
      // box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1) !important;
      transition: all 0.3s ease-in-out;
      padding: 2.25rem 0;
      position: relative;
      will-change: transform;
      // height: 460px;
      min-height: 100%;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0%;
        height: 5px;
        background-color: theme-color(site-primary);
        transition: 0.5s;
      }

      &:hover {
        transform: scale(1.05);
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1) !important;

        &:after {
          width: 100%;
        }
        .btn-gradient {
          // &:hover {
          color: white;
          background-color: theme-color(site-primary);
        }
        // }
      }
      .card-header {
        background-color: white;
        padding-left: 2rem;
        border-bottom: 0px;
      }
      .card-title {
        margin-bottom: 1rem;
      }
      .card-block {
        padding-top: 0;
      }
      .list-group-item {
        border: 0px;
        padding: 0.25rem;
        color: #808080;
        font-weight: 300;
      }

      // Price
      .display-2 {
        font-size: 7rem;
        letter-spacing: -0.5rem;
        line-height: 1;
        .currency {
          font-size: 2.75rem;
          position: relative;
          font-weight: 400;
          top: -45px;
          letter-spacing: 0px;
        }
        .period {
          font-size: 1rem;
          color: lighten(#808080, 20%);
          letter-spacing: 0px;
        }
      }
      .period {
        font-size: 1rem;
        color: lighten(#808080, 20%);
        letter-spacing: 0px;
      }
      .custom {
        font-size: 4.5rem;
        letter-spacing: -0.2rem;
        @media (min-width: 992px) and (max-width: 1199.98px) {
          font-size: 4rem;
        }
        @media (max-width: 499.98px) {
          font-size: 4rem;
        }
      }

      // Buttons
      .btn {
        text-transform: uppercase;
        font-size: 0.75rem;
        font-weight: 500;
        color: lighten(#808080, 15%);
        border-radius: 0;
        padding: 0.75rem 1.25rem;
        letter-spacing: 1px;
      }

      .btn-gradient {
        background-color: #f2f2f2;
        transition: background 0.3s ease-in-out;

        // &:hover {
        //   color: white;
        //   background-color: theme-color(site-primary);
        // }
      }
    }
    .p-card {
      padding: 30px 40px;
      font-family: "Oxygen", sans-serif;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-items: center !important;
      color: black;
      border: 2px solid theme-color(site-primary);
      border-radius: 15px;
      @media (max-width: 1430px) {
        padding: 30px;
      }
      @media (max-width: 1300px) {
        padding: 25px;
      }
      @media (max-width: 1200px) {
        padding: 20px;
      }
      @media (max-width: 800px) {
        padding: 15px;
      }
      @media (max-width: 450px) {
        padding: 30px;
      }
    }
    .range {
      font-weight: bold;
      color: theme-color(site-primary);
      font-size: 90%;
      @media (max-width: 1200px) {
        font-size: 85%;
      }
      @media (max-width: 800px) {
        font-size: 75%;
      }
      @media (max-width: 500px) {
        font-size: 80%;
      }
    }
    .price {
      padding: 10px;
      font-weight: 900;
      @media (max-width: 990px) {
        font-size: 2.2rem;
      }
    }
    p {
      color: theme-color(site-muted);
      font-weight: bold;
      @media (max-width: 800px) {
        font-size: 0.95rem;
      }
    }
    .su-btn {
      margin-top: 15px !important;
      width: 100%;
      color: white;
      font-size: 90%;
      margin: 0;
      @media (max-width: 800px) {
        font-size: 80%;
      }
      @media (max-width: 500px) {
        font-size: 90%;
      }
    }
  }
  img {
    max-width: 280px;
  }
  .pricing-btn {
    font-weight: 700;
    position: absolute;
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    padding: 0;
    border-radius: 100px;
    top: calc(85%);
    left: 50%;
    transform: translateX(-50%);
  }
  .price-header {
    position: absolute;
    top: 0;
    left: 3%;
    height: 35%;
    gap: 8px;
    width: 54%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    h3{
      font-weight: 900;
      font-size: 36px;
    }
    h5{
      font-weight: 600;
      font-size: 12px;
    }
  }
  .price-body{
    position: absolute;
    top: 62%;
    left: 50%;
    width: 55%;
    transform: translateX(-50%);
    text-align: justify;
    font-size: 12px;
    color: white;
    z-index: 10;
  }
}

//Charity
.charity {
  &_bg {
    background-image: url("../img/Landing\ page-slicing/charityBG.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 500px;
    @media (max-width: 992px) {
      height: 400px;
    }
    @media (max-width: 767.98px) {
      height: 400px;
      background-color: white;
      background-image: none;
    }
    .title {
      text-align: center;
      padding: 30px;
      font-weight: bolder;
      // margin-top: 50px !important;
      font-size: 3.7rem;
      @media (max-width: 1150px) {
        font-size: 2.5rem;
      }
      @media (max-width: 990px) {
        font-size: 2.4rem;
      }
      @media (max-width: 768px) {
        // font-size: 1.75rem;
      }
      @media (max-width: 500px) {
        font-size: 2.3rem;
        padding: 30px;
      }
      // @media (max-width: 400px) {
      //   font-size: 1.2rem;
      // }
    }
  }

  .secondary-text {
    font-size: 1.3rem;
    font-weight: 500;
    @media (max-width: 1400px) {
      font-size: 1.2rem;
    }
    @media (max-width: 990px) {
      font-size: 1.1rem;
    }
    @media (max-width: 767.98px) {
      font-size: 1rem;
    }
  }
  .title {
    text-align: center;
    padding: 40px;
    font-weight: bolder;
    font-size: 2.3rem;
    @media (max-width: 1150px) {
      font-size: 1.8rem;
    }
    @media (max-width: 990px) {
      font-size: 1.7rem;
    }
    @media (max-width: 768px) {
      font-size: 1.35rem;
    }
    @media (max-width: 500px) {
      font-size: 1.2rem;
      padding: 30px;
    }
    // @media (max-width: 400px) {
    //   font-size: 1.2rem;
    // }
  }
}
//reuest a demo section
.requestDemo {
  &__btn {
    border-radius: 12px !important;
  }
}
.page-footer {
  // background-color: white;
  z-index: 999;

  .navbar-header {
    img {
      width: 50%;
      display: flex;
      margin: auto;
    }
    @media (max-width: 670px) {
      margin: auto;
      text-align: center;
    }
  }
  .footer-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
    // margin-left: -250px;
    @media (max-width: 990px) {
      justify-content: center;
      margin-left: 0;
    }
    @media (max-width: 670px) {
      justify-content: center;
      // margin-right: 35px;
    }
    .footer-btn {
      width: 30%;
      cursor: pointer;
      margin: 10px;
      img {
        width: 100%;
      }
    }
  }
  .footer-social {
    margin-left: auto !important;
    justify-content: end;
    display: flex;
    margin: auto;
    @media (max-width: 990px) {
      // margin: auto;
      justify-content: center;
    }
    .social-btn {
      width: 12%;
      @media (max-width: 1450px) {
        width: 100%;
      }
      // margin-right: 20px;
      img {
        width: 100%;
      }
    }
  }
  .footer-navitems {
    display: flex;
    padding-bottom: 50px;
    .navbar-nav {
      flex-direction: row !important;
      .navlink {
        padding: 10px;
        color: $body-color;
        font-size: 1rem;
        cursor: pointer;
        &:hover {
          text-decoration: none;
          color: theme-color("site-primary");
        }
        @media (max-width: 400px) {
          font-size: 0.8rem;
          padding: 5px;
        }
      }
      .nav-btn {
        border-radius: 50px;
        font-size: 1.1rem;
        font-weight: 600;
        padding: 5px 20px;
        margin: 0px 10px;
      }
    }
  }
  .contct {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 50px;
    @media (max-width: 670px) {
      display: grid;
      // margin-top: 20px;
    }
    a {
      color: $body-color;
      text-decoration: none;
      margin-top: 10px;
      img {
        // width: 8%;
        margin-top: -5px;
        margin-right: 10px;
        margin-left: 50px;
        @media (max-width: 870px) {
          margin-right: 5px;
          margin-left: 0;
        }
      }
    }
  }
  .footer-logo {
    height: auto !important;
    width: 160px !important;
    transform: translateY(-10%);
  }
  .wave-section-separator {
    z-index: -1 !important;
    .wave {
      fill: #d8daea;
    }
  }
}

//contact

.Contact {
  margin: auto;
  margin-top: 50px;
  // height: 170vh;
  min-height: 170vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;

  @media (max-width: 767.99px) {
    min-height: 120vh !important;
  }

  h1 {
    font-size: 2.5rem;
    @media (max-width: 1150px) {
      font-size: 2rem;
    }
    @media (max-width: 990px) {
      font-size: 1.9rem;
    }
    @media (max-width: 768px) {
      font-size: 1.55rem;
    }
    @media (max-width: 500px) {
      font-size: 1.4rem;
    }
    @media (max-width: 400px) {
      font-size: 1.2rem;
    }
  }
  .card {
    background-color: theme-color("site-primary");
    padding: 50px 30px;
    margin: 50px 50px;

    // @media (min-width: 768px) {
    //   width: 90% !important;
    // }

    // @media (max-width: 767.99px) and (min-width: 280px) {
    //   width: 80% !important;
    // }

    // @media (max-width: 767.99px) {
    //   margin: 50px 90px;
    // }

    // @media (max-width: 1200px) {
    //   padding: 40px 25px;
    //   margin: 50px 130px;
    // }
    // @media (max-width: 990px) {
    //   padding: 35px 20px;
    //   margin: 50px 110px;
    // }
    // @media (max-width: 830px) {
    //   padding: 30px 20px;
    //   margin: 50px 90px;
    // }

    // @media (max-width: 751px) {
    //   padding: 15px 15px;
    //   margin: 50px 60px;
    // }
    // @media (max-width: 500px) {
    //   padding: 15px 15px;
    //   margin: 50px 30px;
    // }
    border: none;
    .form-group {
      margin-bottom: 1.5rem;
      .form-control {
        padding: 2rem 1.75rem;
        font-size: 1.2rem;
        @media (max-width: 1200px) {
          font-size: 1.1rem;
        }
        @media (max-width: 990px) {
          font-size: 1rem;
        }
        @media (max-width: 830px) {
          font-size: 0.9rem;
        }
        @media (max-width: 751px) {
          font-size: 0.8rem;
        }
        @media (max-width: 500px) {
          font-size: 0.6rem;
        }
      }
    }
    Button {
      padding: 15px;
      font-size: 1.5rem;
      margin: 20px 0px 10px;
      color: theme-color("site-primary");
    }
  }
}

.tabs__panel {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 5rem;
}

.tab-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.d-flex {
  padding: 1.5rem 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-link:focus,
.nav-link > a.active {
  background-color: #d1d5e0 !important;
  color: #211db2 !important;
  &:active {
    background-color: transparent !important;
  }
}
.tab__heading {
  color: #060a0d !important;
  text-decoration: none !important;
  @media (max-width: 500px) {
    font-size: 1.2rem;
  }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent !important;
}

///wave

.wave-section-separator {
  width: 100vw;
  position: absolute;
  bottom: 0px;
  div {
    /*   background-color:#0129ac; */
    margin: -5px 0px 0px 0px;
    padding: 0px;
    // color: #fff;
    text-align: center;
  }
  svg {
    width: 100%;
  }

  .wave {
    animation: wave 6s linear;
    animation-iteration-count: infinite;
    // fill: #d8daea;
  }
  .gooeff {
    filter: url(#goo);
  }
  #wave2 {
    animation-duration: 8s;
    animation-direction: reverse;
    opacity: 0.6;
  }
  #wave3 {
    animation-duration: 10s;
    opacity: 0.3;
  }
}

@keyframes wave {
  to {
    transform: translateX(-100%);
  }
}

//Phone Number component
.PhoneInput {
  height: 60px !important;
  background: #f3f4ff !important;
  padding: 0.375rem 0.75rem;
  color: #495057;
  .PhoneInputInput {
    border: none;
    border-left: 1px solid #dacdcd !important;
    background: transparent;
    padding-left: 5px !important;
    color: #495057;
    &:focus-visible {
      background: transparent !important;
      border: none !important;
      border-left: 1px solid #dacdcd !important;
      border-color: #f3f4ff !important;
      outline: none !important;
    }
    &:focus-visible {
      border-color: #f3f4ff !important;
    }
  }
}

.stripe-input {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border: 0 !important;
  justify-content: space-between !important;
}
.cursor-pointer {
  cursor: pointer;
}

// blogs list scss
.blogSection h6 {
  font-size: 40px;
  font-weight: 600;
}
a:hover {
  text-decoration: none !important;
}
.blogSection {
  &__card {
    background: white;
    padding: 10px;
    border-radius: 10px !important;
    &__date {
      font-size: 12px;
      font-weight: 200;
      margin: 0px !important;
      color: theme-color("site-primary");
    }
  }
  &__button {
    margin-top: 20px;
  }

  &__inner {
    background: white;
    width: 100%;
    height: 140px;
    margin-top: 10px;
    &__title {
      color: black;
      font-weight: 600;
      font-size: 20px;
    }
    &__text {
      color: black;
      font-size: 14px;
      font-weight: 300;
    }
    &__img {
      height: 12rem;
      object-fit: contain;
      border-radius: 20px;
      width: 100%;
      object-fit: cover;
    }

    &__button {
      background: theme-color("site-primary");
      border-radius: 4px;
      color: #ffffff;
      margin-left: 10px;
      border: none;
      outline: none;
      z-index: 999;
      cursor: pointer;
      padding: 10px 20px;
    }
  }
  ​ &__buttonGroup {
    background: theme-color("site-primary");
    border-radius: 48px;
  }
  .border-top {
    border-top: 1px solid #d7d5d3 !important;
  }
}
@media screen and (max-width: 992px) {
  .blogSection__inner {
    // height: 18.25rem !important;
  }
}
@media screen and (max-width: 357px) {
  .blogSection__inner {
    // height: 25.25rem !important;
  }
}

// single blog

.singleBlog {
  margin-top: 10rem !important;
  &__title {
    font-size: 2.8rem;
    line-height: 4.5rem;
    font-weight: 800;
  }
  &__featureImage {
    width: 100%;
  }
  &__description {
    margin-top: 40px;
    // line-height: 3.575rem;
  }
  &__content {
    line-height: 1.625rem;
  }
}

// Loader.scss
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}
.encrypted_paragraph {
  // font-size: 17.7px !important;
  // text-align: justify;
  // @media (max-width: 1440px) {
  //   font-size: 28px !important;
  // }
  // @media (max-width: 1024px) {
  //   font-size: 20.5px !important;
  // }
  @media (max-width: 1095px) {
    padding: 0rem 1.25rem !important;
    justify-content: center;
    .text-left {
      padding: 0px !important;
      margin: 0px !important;
    }
    .pr-5 {
      padding: 0px !important;
    }
  }

  @media (max-width: 760px) {
    h6 {
      text-align: center;
    }
    h1 {
      text-align: center;
    }
  }
  // @media (max-width: 320px) {
  //   font-size: 12px !important;
  // }
  // @media (max-width: 400px) {
  //   font-size: 31.7px !important;
  // }
  h6 {
    line-height: 23px;
  }
}

.home-side-padding {
  margin-left: 11rem;
  margin-right: 11rem;
  @media (max-width: 1800px) {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  @media (max-width: 1400px) {
    margin-left: 4.5rem;
    margin-right: 4.5rem;
  }
  @media (max-width: 1200px) {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
  @media (max-width: 768px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.benefit_subText {
  @media (max-width: 1100px) {
    font-size: 11px !important;
  }
  @media (max-width: 1024px) {
    font-size: 10px !important;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.free-pricng-animation {
  display: grid;
  // gap: 1rem;
  grid-template-columns: auto auto auto auto;
  @media (max-width: 950px) {
    grid-template-columns: auto auto;
  }
  @media (max-width: 500px) {
    grid-template-columns: auto;
  }
}
