@import "react-calendar/dist/Calendar.css";

.react-calendar { 
  width: 400px;
  max-width: 100%;
  background-color: #fff;
  color: #222;
  border-radius: 8px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  border: 1px solid lightgray;
 }
 .react-calendar__navigation button {
  color: #6f48eb;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
 }
 .react-calendar__navigation button:enabled:hover,
 .react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
 }
 .react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
 }
 abbr[title] {
  text-decoration: none;
 }
 /* .react-calendar__month-view__days__day--weekend {
  color: #d10000;
 } */
 .react-calendar__tile:enabled:hover,
 .react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: #6f48eb;
  border-radius: 6px;
 }
 .react-calendar__tile--now {
  background: #87878733;
  border-radius: 6px;
  font-weight: bold;
  color: #6f48eb;
 }
 .react-calendar__tile--now:enabled:hover,
 .react-calendar__tile--now:enabled:focus {
  background: #6f48eb33;
  border-radius: 6px;
  font-weight: bold;
  color: #6f48eb;
 }
 .react-calendar__tile--hasActive:enabled:hover,
 .react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
 }
 .react-calendar__tile--active {
  background: #6f48eb;
  border-radius: 6px;
  font-weight: bold;
  color: white;
 }
 .react-calendar__tile--active:enabled:hover,
 .react-calendar__tile--active:enabled:focus {
  background: #6f48eb;
  color: white;
 }
 .react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
 }
 .react-calendar__tile--range {
  background: #f8f8fa;
  color: #6f48eb;
  border-radius: 0;
 }
 .react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #6f48eb;
  color: white;
 }
 .react-calendar__tile--rangeEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #6f48eb;
  color: white;
 }

 .events{
  &__single{
    border: 1px solid gray;
    border-radius: 6px;
    display: flex;
    // justify-content: space-between;
    // align-items: center;
    p{
      margin-bottom: 0;
    }
  }
 }

 //Clock

 .clock_div {
  display: flex;
  justify-content: center;
  align-items: center;
  #clock{
    width: 100%;
  }
}

.atp-clock-btn {
  display: none !important;
}
.atp{
  box-shadow: none;
}

// .button {
//   background-color: black; /* Green */
//   border: none;
//   color: white;
//   margin-top: 13px;
//   padding: 10px 20px;
//   text-align: center;
//   cursor: pointer;
//   text-decoration: none;
//   display: inline-block;
//   font-size: 16px;
// }

// .mat-card {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// }

.atp-time {
  background-color: rgb(86, 86, 86) !important;
  text-align: center !important;
  font-size: 35px;
  border-radius: 16px;
}

.atp-color--primary {
  background-color: #1890ff;
}

.atp-clock-cnt {
  display: none;
  background-color: rgb(82, 82, 82) !important;
  border-radius: 50%;
}

// .atp-clock {

//   background-color: rgb(82, 82, 82) !important;
//   border-radius: 50%;
// }

// .atp-face-color {
//   background-color: rgb(82, 82, 82) !important;
// }

// .atp-n {
//   color: white !important;
// }

// .atp-h {
//   background-color: #1890ff !important;
// }

